// TODO: (WT-2093) Safari/desktop, clicking a suggestion while spell check box is showing re-populates suggestions.data after clearSuggestions()
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import dynamic from 'next/dynamic'
import usePlacesAutocomplete from 'use-places-autocomplete'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import Image from 'next/image'
import type { SearchState } from 'react-instantsearch-core'
import classNames from 'classnames'
import type { Suggestions } from 'use-places-autocomplete'

import { useDispatch, useSelect } from 'store/index'

import { setMobileSearchBoxFocused } from 'reducers/uiState'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import style from './SearchBox.module.scss'

import { DesktopBreakpoint } from 'config/Breakpoints'

import { pushToDataLayer } from 'utils/Gtm'
import urlToSearchState from 'utils/search/urlToSearchState'

import PinIcon from 'assets/icons/icon-pin.svg'
import poweredByGoogle from 'assets/logos/powered-by-google-logo.png'
import CloseIcon from 'assets/icons/icon-close.svg'
import TravelIcon from 'assets/icons/traveler-stroke.svg'
import DarkSearchIcon from 'assets/icons/icon-search-dark.svg'

const RecentSearches = dynamic(() => import('./RecentSearches/RecentSearches'))

export type SearchBoxProps = {
  onQueryChange?: (query: string) => void
  onQuerySelect?: (...args: any[]) => any
  initialAddress?: string
  mobileMenuActive?: any
  currentRefinement?: string
  defaultRefinement?: string
  refine?: (...args: any[]) => SearchState
}

const SearchBox: React.FC<SearchBoxProps> = ({
  onQueryChange,
  onQuerySelect,
  currentRefinement,
  refine,
  initialAddress,
  mobileMenuActive,
}) => {
  const router = useAugmentedRouter()
  const [focused, setFocused] = useState(false)
  const [mobileFocused, setMobileFocused] = useState(false)
  const [queryInput, setQueryInput] = useState(
    urlToSearchState(router.asPath).query ?? '',
  )

  const width = useSelect((state) => state.uiState.width)

  const fullScreenRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const fieldRef = useRef<HTMLDivElement>(null)

  const isSearchPage = useMemo(
    () => router.asPath.includes('/vacation-rentals/search'),
    [router.asPath],
  )

  const showRecentSearches = useMemo(
    () => ((width > 600 && focused) || mobileFocused) && !queryInput,
    [focused, mobileFocused, queryInput, width],
  )

  const datePickerFocused = useSelect(
    (state) => state.uiState.datePickerFocused,
  )

  const {
    init: initPlaces,
    suggestions,
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    initOnMount: false,
    requestOptions: {
      bounds: {
        south: 28.7,
        west: -127.5,
        north: 48.85,
        east: -55.9,
      },
    },
  })

  const appDispatch = useDispatch()

  const handleSelect = useCallback(
    (place: string) => {
      // push location search event to GTM data layer
      window.dataLayer &&
        pushToDataLayer('areaSearched', { areaSearched: place })

      // only allow a user to select a place from the dropdown

      if (place !== undefined) {
        setQueryInput(place)
        if (onQuerySelect) {
          onQuerySelect(place)
        }
      }

      clearSuggestions()

      if (refine) {
        refine(place)
      }

      if (width <= DesktopBreakpoint) {
        fullScreenRef.current && enableBodyScroll(fullScreenRef.current)

        inputRef.current?.blur()
      }

      setTimeout(() => {
        setMobileFocused(false)
      })
    },

    [clearSuggestions, onQuerySelect, refine, width],
  )

  const handleFocus = () => {
    if (width <= DesktopBreakpoint && !mobileFocused && fullScreenRef.current) {
      setMobileFocused(true)
      disableBodyScroll(fullScreenRef.current)

      setTimeout(() => {
        window.scrollTo(0, 0)
        document.body.scrollTop = 0
      }, 100)
    }

    setFocused(true)
    // If maps not loaded, append the script, else init places hook
    window.google && initPlaces()
  }

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = () => {
    const [suggestion] = filterSuggestions(suggestions)

    if (suggestion) {
      const { description } = suggestion
      handleSelect(description)
      onQueryChange?.(description)
    }
  }

  const filterSuggestions = (suggestions: Suggestions) => {
    return suggestions.data.filter((suggestion) => {
      return ['geocode', 'natural_feature', 'neighborhood'].some((type) =>
        suggestion.types.includes(type),
      )
    })
  }

  useEffect(() => {
    const timeoutId = window.setTimeout(() => {
      const [suggestion] = filterSuggestions(suggestions)

      if (suggestion) {
        const { description } = suggestion
        onQueryChange?.(description)
      }
    }, 750)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [onQueryChange, queryInput, suggestions])

  const handleKeyDown = (evt) => {
    if (evt.key === 'Enter') {
      const [suggestion] = filterSuggestions(suggestions)

      if (suggestion) {
        const { description } = suggestion
        handleSelect(description)
        onQueryChange?.(description)
      }
    }
  }

  const handleChange = (searchQuery: string) => {
    if (searchQuery !== undefined) {
      setValue(searchQuery)
      setQueryInput(searchQuery)

      if (onQueryChange) {
        onQueryChange(searchQuery)
      }
    }
  }

  const clearInput = () => {
    setQueryInput('')
    clearSuggestions()

    if (onQueryChange) {
      setTimeout(() => {
        onQueryChange('')
      })
    }
  }

  const handleCancel = () => {
    clearInput()
    clearSuggestions()
    setMobileFocused(false)

    if (width <= DesktopBreakpoint && fullScreenRef.current) {
      enableBodyScroll(fullScreenRef.current)
    }
  }

  //handle initialAddress for landing pages
  useEffect(() => {
    if (initialAddress) {
      handleSelect(initialAddress)
      setMobileFocused(false)
    }
  }, [handleSelect, initialAddress])

  useEffect(() => {
    if (width > DesktopBreakpoint && mobileFocused && fullScreenRef.current) {
      setFocused(false)
      enableBodyScroll(fullScreenRef.current)

      setTimeout(() => {
        window.scrollTo(0, 0)
        document.body.scrollTop = 0
      }, 100)
    }
  }, [width, mobileFocused])

  useEffect(() => {
    appDispatch(setMobileSearchBoxFocused(mobileFocused))
  }, [appDispatch, mobileFocused])

  // If the search state updates and the query is empty, clear the input
  useEffect(() => {
    if (!currentRefinement && !initialAddress) {
      setQueryInput('')
    }
  }, [currentRefinement, initialAddress])

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      e.stopPropagation()
      const isMobile = width <= 600
      if (isMobile) return
      const clickWasOutside =
        !fieldRef.current?.contains(e.target as Node) || datePickerFocused

      if (clickWasOutside) {
        setFocused(false)
        inputRef.current?.blur()
      } else {
        inputRef.current?.focus()
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
      setFocused(false)
    }
  }, [width, datePickerFocused])

  useEffect(() => {
    initPlaces()
  }, [initPlaces, router.asPath])

  const showMobileIcon = useMemo(() => {
    if (
      router.pathname === '/favorites' ||
      (router.pathname === '/vacation-rentals/[...slug]' &&
        Number(router.query.slug?.[router.query.slug.length - 1]))
    ) {
      if (width <= DesktopBreakpoint && !mobileFocused) {
        return true
      }
    }
    return false
  }, [mobileFocused, router.pathname, router.query.slug, width])

  return (
    <>
      <div ref={fullScreenRef}>
        <div
          className={`${style.searchBox} ${
            mobileFocused ? style.focused : ''
          } ${mobileMenuActive ? style.mobileMenuActive : ''}`}
          ref={fullScreenRef}
        >
          <div
            className={classNames(style.footer, {
              [style.focused]: mobileFocused,
            })}
          >
            <button className={style.btn} onClick={() => handleCancel()}>
              Cancel
            </button>
          </div>
          {showMobileIcon ? (
            <DarkSearchIcon
              className={style.mobileSearchIcon}
              onClick={() => handleFocus()}
            />
          ) : (
            <div
              className={style.desktopSearchBox}
              onFocus={handleFocus}
              ref={fieldRef}
              role="textbox"
              tabIndex={0}
            >
              <PinIcon
                className={style.searchIcon}
                width={isSearchPage ? 30 : 40}
              />
              <input
                className={`${style.searchInput} ${
                  queryInput ? style.hasValue : ''
                }`}
                data-testid={
                  isSearchPage ? 'inputSearchLocation' : 'inputSearchByLocation'
                }
                onChange={(e) => handleChange(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder={
                  isSearchPage ? 'Search location' : 'Search by location'
                }
                ref={inputRef}
                value={queryInput}
              />
              {queryInput && (
                <CloseIcon className={style.clearIcon} onClick={clearInput} />
              )}
              {/* Query Suggestions */}
              <div
                className={`${style.suggestions} ${
                  focused && suggestions.data.length ? style.hasSuggestions : ''
                }`}
              >
                {suggestions.loading && <div>Loading...</div>}
                {suggestions.data
                  .filter((suggestion) =>
                    ['geocode', 'natural_feature', 'neighborhood'].some(
                      (type) => suggestion.types.includes(type),
                    ),
                  )
                  .map((suggestion) => {
                    return (
                      <div
                        className={style.suggestionItem}
                        key={suggestion.place_id}
                        onClick={() => {
                          if (onQueryChange) {
                            onQueryChange(suggestion.description)
                          }
                          handleSelect(suggestion.description)
                        }}
                      >
                        <PinIcon className={style.suggestionIcon} width={24} />
                        <div className={style.suggestionText}>
                          <div className={style.suggestionMainText}>
                            <span className={style.highlightedQuery}>
                              {suggestion.structured_formatting.main_text}
                            </span>
                          </div>
                          <div className={style.suggestionSecondaryText}>
                            <span className={style.highlightedQuery}>
                              {suggestion.structured_formatting.secondary_text}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                <div className={style.poweredByGoogle}>
                  <Image
                    alt="Powered by Google"
                    src={poweredByGoogle}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
              </div>
              {/* Recent Searches */}
              {showRecentSearches && !suggestions.data.length ? (
                <RecentSearches focused={focused} />
              ) : null}
              {!suggestions.data.length && !showRecentSearches ? (
                <div
                  className={`${style.overview} ${
                    mobileFocused ? style.focused : ''
                  }`}
                >
                  <TravelIcon />
                  <span>Where to next?</span>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default SearchBox
